body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

body.login-content {
  height: 100vh;
}

.login-content div#root {
  height: 100%;
}

a {
  text-decoration: none;
  color: black;
}

.login-button {
  background: #FF8F00;
  color: white;
  width: 231px;
}


body.login-content {
  background-image: url('/img/login/loginBackground.jpg');
  background-size: 100% 100%;
  background-position: center;
}

.login-content .signGoogle button {
  font-family: 'The Sans Veolia', sans-serif !important;
  font-size: 12px !important;
  max-width: 180px !important;
  cursor: pointer;
  background-color: #00aec7 !important;
  color: white !important;
  box-shadow: unset !important;
  border-radius: 10px;
  width: 231px;
  height: 35px;
}

.login-content .signGoogle button:hover {
  cursor: pointer;
  opacity: 0.9;
}

.login-content .signGoogle button span span{
  padding: 10px !important;
}
